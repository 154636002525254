.loading-full-screen{
    position: fixed;
    top: -10%; 
    left: 0; 
    z-index: 999;
    width: 100vw; 
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center; 
    justify-content: center;
    scale: 1.5;
  }