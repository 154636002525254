
.btn-paynow {
    display: inline-block;
    padding: 0.0625rem 0.3125rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    white-space: nowrap;
    color: #fff;
    background-color: #3aadaa;
    border-color: rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.1);
        cursor: pointer;
        filter: brightness(1.2);
    }
}
  
  