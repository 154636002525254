.body-login {
    width: 100%;
    min-height: 100vh;
  



    .container-login{
        width: 100%;
        max-width: 420px;
        min-height: 400px;

        img{
            width:50%;
            padding-bottom: 0.5em;
            }


        .login {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 1.5rem;
            background-color: #ffffff;
            border-radius:4px;
            color: var(--primary-text);
            font-weight:600;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                
        .dropdown-toggle{
            color: var(--secondary-text);
            font-size: 1em;
            background-color:#ffffff;
            border: 1px solid #ffffff;
            margin-bottom:2em;
            } 

            form{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                flex-grow: 1;
                padding-bottom: 1rem;
    
                input[type='text'],input[type='password']{
                    font-family: var(--font-titillium);
                    font-size: 1rem;
                    padding: .6rem .9rem;
                    border-radius: 4px;
                    border: 1px solid #c6c6c6;
                    margin-top: 0.3rem;
                    margin-bottom: 1.8rem;
                }
                 input[type='text']:focus,input[type='password']:focus{
                    border-color: 1px solid var(--primary-color);
                }
                 input[type='text']:focus-visible,input[type='password']:focus-visible{
                    border-color: 1px solid var(--primary-color);
                }

                label{
                    margin-top: 0rem;
                    margin-bottom:0rem;
                }

                button{
                   padding: 0.5em;
                   background-color: var(--primary-color);
                   border: 1px solid var(--primary-color);
                   color: var(--text-white);
                   border-radius: 4px;
                   font-weight: 600;
                   padding: 0.8rem;
                   margin-top: 1rem;
                   line-height: var(--bs-body-line-height);  
                }

                button:hover{
                   background-color: var(--secondary-color);
                   border: 1px solid var(--secondary-color);
                }

                p{
                    padding-bottom:2.5em;
                    text-align: center;


                }
                
            }

            .errmsg {
                background-color: lightpink;
                color: firebrick;
                font-weight: bold;
                padding: 0.5rem;
                margin-bottom: 0.5rem;
            }

            .offscreen {
                position: absolute;
                left: -9999px;
            }
            
            .link{
                color: var(--secondary-text);
                text-decoration: none;
                text-align:right;
                font-weight:400;
              }

            .link:hover{
                color: var(--secondary-color);
                cursor: pointer;
            }
              
        }
    }
}

