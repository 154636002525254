@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700&display=swap');


:root{
    /*Fonts*/
    
    --font-titillium: 'Titillium Web', sans-serif;

    /*Colors*/

    --primary-text: #333333;
    --secondary-text: #808080;
    --text-white: #ffffff;
    --primary-color: #0079c1;
    --secondary-color: #00457c;

    --bg-danger: #ffece3;
    --text-danger: #ff7f41;
    --bg-success: #dbf8f4;
    --text-success: #11d1b7;
    --bg-info: #bfddf0;
    --text-info: #0079c1;
    --bg-warning: #fff6e7;
    --text-warning: #ffc061;

    --btn-success: #11d1b7;
    --btn-success-hover: #0eb29c;
    --btn-info: #0079c1;
    --btn-info-hover: #2d65cd;
    --btn-danger:#f06548;
    --btn-danger-hover:#cc563d;

    /*Breakpoints*/
}