html, body{
    font-family: var(--font-titillium);
    font-size: 1em;
    color: #fff;
    background-color: #f7f7f9;
}

.bg-header {
    background-color: var(--secondary-color);
}

.logo-var {
    border-left: 1px solid #ffffff;
    padding: 0.25em;
}

 .table-shadow{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 }

 .table-column-name {
    text-transform: uppercase;
    font-weight: 600;
 }

 .bg-danger-ipb {
    background-color: var(--bg-danger);

 }

 .text-danger-ipb {
    color: var(--text-danger);
 }

 .badge-danger-ipb {
    background-color: var(--bg-danger);
    color: var(--text-danger);
    text-transform: uppercase;
    font-size:1em;
    text-align: center;
 }

 .bg-success-ipb {
    background-color: var(--bg-success);
 }

 .text-success-ipb {
    color: var(--text-success);
 }

.badge-success-ipb {
    background-color: var(--bg-success);
    color: var(--text-success);
    text-transform: uppercase;
    font-size:1em;
 }

.bg-info-ipb {
    background-color: var(--bg-info);
 }

 .text-info-ipb {
    color: var(--text-info);
 }

.badge-info-ipb {
    background-color: var(--bg-info);
    color: var(--text-info);
    text-transform: uppercase;
    font-size:1em;



 }

 .bg-warning-ipb {
    background-color: var(--bg-warning);
 }

 .text-warning-ipb {
    color: var(--text-warning);
 }

.badge-warning-ipb {
    background-color: var(--bg-warning);
    color: var(--text-warning);
    text-transform: uppercase;
    font-size:1em;

 }

 .btn-paynow-ipb {
    background-color: var(--btn-success);
    color: #ffffff;
    border:none;
    border-radius: 4px;
    font-size: 1em;
    font-weight:600;
    text-transform: uppercase;
    padding: 0.35em 0.65em;
 }

 .btn-paynow-ipb:hover {
    background-color: var(--btn-success-hover);
 }

 .btn-downloadpdf-ipb {
    background-color: var(--btn-info);
    color: #ffffff;
    border:none;
    border-radius: 4px;
    font-size: 1em;
    font-weight:600;
    padding: 0.35em 0.65em;
 }

 .btn-downloadpdf-ipb:hover {
    background-color: var(--btn-info-hover);
 }

.bg-primary {
    background-color: var(--primary-color); 
  
}

.btn-primary{
    background-color: var(--btn-info); 
    border: 1px solid var(--btn-info);
    
}

.btn-primary:hover{
background-color: var(--btn-info-hover);
    border: 1px solid var(--btn-info-hover);
    
}
.btn-danger {
    background-color: var(--btn-danger); 
    border: 1px solid var(--btn-danger);
}
.btn-danger:hover{
    background-color: var(--btn-danger-hover); 
    border: 1px solid var(--btn-danger-hover);
}
.btn-success{
    background-color: var(--btn-success); 
    border: 1px solid var(--btn-success);
    font-weight:600;
}
.btn-success:hover{
    background-color: var(--btn-success-hover); 
    border: 1px solid var(--btn-success-hover);
}


.btn-success-ipb{
    background-color: var(--btn-success); 
    border: 1px solid var(--btn-success);
    color: #ffffff;
}
.btn-success-ipb:hover{
    background-color: var(--btn-success-hover); 
    border: 1px solid var(--btn-success-hover);
}

.main-title {
    font-size: 1.5em;
    padding-bottom: 1em;
}

.filters-block input {
    margin-bottom:0;
    width: 150px !important;
}

.modal-title {
    font-size: 1.5em; 
}
.fs-12 {
    font-size:12px;
}

.button-payment {
    font-weight:600;
}